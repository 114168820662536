<template>
  <div>
    <div :class="customClass" class="search_field rounded-lg justify-between px-2 items-center flex">
      <input
          :id="id"
          v-model="model"
          :type="type"
          :name="name"
          :maxlength="maxlength"
          :placeholder="label"
          @keydown.enter="$emit('enter')"
          @keydown="$emit('keydown', $event)"
          class="w-full text-[#3C4549] text-sm font-poppins"
          @focus="labelFocused = true"
          @blur="labelFocused = false"
          style="border:none;"
        />
      <div>
        <slot name="right_btn"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InputButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array],
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    maxlength: {
      type: [Number, String],
      default: 524288,
    },
    customClass: {
      type: String,
      default: 'with_border'
    }
  },
  data() {
    return {
      labelFocused: false
    }
  },
  computed: {
    name() {
      return this.label.toLowerCase()
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style lang="less" scoped>
.search_field {
  max-width: 11.875rem;
  min-width: min-content;
  input {
    display: none;
    height: 1.850rem;
    padding: 0;
  }
  &:hover, &:focus-within {
    max-width: 11.875rem;
    input {
      display: block;
    }
  }
  input:not(:placeholder-shown), input:focus {
    display: block;
  }
}

.with_border {
  border: 1px solid #757A8A;
}

.without_border {
  border: 1px solid #ECEEF5;
  background-color: #F2F3F8 !important;
  input {
    background-color: #F2F3F8;
  }
}
</style>
